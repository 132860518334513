// Define breakpoints for responsive design
const breakpoints = {
	sm: 415,
	md: 768,
	lg: 1280,
	xl: 1440,
	'2xl': 1920,
	'3xl': 3840,
};

// Initialize event listeners when the document is ready
$(document).ready(function () {
	$('#lang-switcher').on('click', toggleLanguageOptions);
	$('.mobile-has-children > a').on('click', toggleMobileSubmenu);
	$('#primary-navigation--button').on('click keypress', function (e) {
		if (e.type === 'click' || (e.type === 'keypress' && e.which === 13)) {
			toggleNavigation(breakpoints);
		}
	});
	$('.lang-switcher--item').on('click', setLanguageSession);
	$('#back-to-top').on('click', scrollToTop);
	$('.accordion').on('click', toggleAccordion);
	loadFlipbooks();
	
	$('a:not(#external-link-modal--link,.no-link-modal)').click(function (event) {
		if (!$(this).attr('href')) {
			return;
		}
		let clickedHref = $(this).attr('href');

		// Check if the href starts with 'http', 'https', or '//'
		if (clickedHref.startsWith('http') || clickedHref.startsWith('//')) {
			if (!clickedHref.startsWith('https://www.lysomed.ch')) {
				event.preventDefault(); // Prevent the link from being followed
				openLinkModal(clickedHref);
			}
		}
	});

	$('.external-link-modal--closer').click(function () {
		closeLinkModal();
	});

	$('#external-link-modal').click(function (event) {
		let clickTarget = $(event.target);
		if (clickTarget.is('#external-link-modal')) {
			closeLinkModal();
		}
	});

	// Listener for opening a modal
    $('[data-toggle="modal"]').click(function () {
        var modalId = $(this).data('target');
        openModal(modalId);
    });

    // Listener for closing a modal
    $('.modal--closer').click(function () {
        var modalId = $(this).closest('.modal').attr('id');
        closeModal(modalId);
    });

    // Close modal when clicking outside of the modal content
    $('.modal').click(function (event) {
        if ($(event.target).is('.modal')) {
            closeModal($(this).attr('id'));
        }
    });
	
});

function openModal(modalId) {
    $('#' + modalId).fadeIn();
    $('body').addClass('overflow-hidden');
}

function closeModal(modalId) {
    $('#' + modalId).fadeOut();
    $('body').removeClass('overflow-hidden');
}

function openLinkModal(href) {
	$('#external-link-modal--link').attr('href', href);
	$('#external-link-modal').fadeIn();
	$('body').toggleClass('overflow-hidden');
}

function closeLinkModal() {
	$('#external-link-modal').fadeOut();
	$('body').toggleClass('overflow-hidden');
}

function loadFlipbooks() {
    $('.flipbook').each(function() { 
        let pdfUrl = $(this).data('pdf');
        let flipbookId = $(this).attr('id');
        $(`#${flipbookId}`).flipBook({ 
            pdfUrl: pdfUrl,
            lightBox: true,
			btnDownloadPages: {
				enabled: false,
			},
        });
    });
}


function toggleAccordion() {
	$(this).find('.accordion-content').slideToggle();
	$(this).find('.accordion-icon').toggleClass('-rotate-180');
}

function scrollToTop() {
	$('html, body').animate({ scrollTop: 0 }, 200, 'linear');
}

// Toggles the display of language options
function toggleLanguageOptions(e) {
	e.preventDefault();
	$('#lang-switcher--options').slideToggle();
}

// Toggles the mobile submenu for elements with class 'mobile-has-children'
function toggleMobileSubmenu(e) {
	e.preventDefault();
	var childUl = $(this).next('ul');
	childUl.slideToggle();
	$(this).closest('.mobile-has-children').toggleClass('open');
}

// Toggles the navigation based on screen size
function toggleNavigation(breakpoints) {
	const width = $(window).width();
	$('#primary-navigation--button').toggleClass('open');

	if (width < breakpoints.md) {
		// Toggle mobile navigation for smaller screens
		$('#mobile-navigation').slideToggle();
		$('#mobile-nav-bg').fadeToggle();
		$('body').toggleClass('overflow-hidden');
	} else {
		// Toggle primary navigation for larger screens
		$('#primary-navigation').slideToggle();
	}
}

// Sets a language preference in session storage
function setLanguageSession() {
	var lang = $(this).data('lang');
	if ($.inArray(lang, ['de', 'fr', 'it']) >= 0) {
		sessionStorage.setItem('lysomed_lang', lang);
	}
}

let onYouTubeIframeAPIReadyCallbacks=[];
"use strict";
	document.addEventListener('DOMContentLoaded',function(){
		if(window.hideYTActivated) {
			return;
		} else {
			if(typeof YT==='undefined') {
				let tag=document.createElement('script');
				tag.src="https://www.youtube.com/iframe_api";
				let firstScriptTag=document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag,firstScriptTag);
			}
			
			for(let playerWrap of document.querySelectorAll(".video-embed-container")) {
				let playerFrame=playerWrap.querySelector("iframe");
				let onPlayerStateChange=function(event) { 
					if(event.data==YT.PlayerState.ENDED) {
						playerWrap.classList.add("ended");
					} else if(event.data==YT.PlayerState.PAUSED) {
						playerWrap.classList.add("paused");
					} else if(event.data==YT.PlayerState.PLAYING) {
						playerWrap.classList.remove("ended");
						playerWrap.classList.remove("paused");
					}
				};
				let player;
				onYouTubeIframeAPIReadyCallbacks.push(function() {
					player=new YT.Player(playerFrame,{events:{'onStateChange':onPlayerStateChange}});
				});
				playerWrap.addEventListener("click",function() {
					let playerState=player.getPlayerState();
					if(playerState==YT.PlayerState.ENDED) {
						player.seekTo(0);
					}
					else if(playerState==YT.PlayerState.PAUSED) {
						player.playVideo();
					}
				});
			}
		}
		window.onYouTubeIframeAPIReady=function(){
			for(let callback of onYouTubeIframeAPIReadyCallbacks) {
				callback();
			}
		};
		window.hideYTActivated=true;
	});